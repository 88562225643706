import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="Page not found" />
      <div>
        <h1>Sorry. We couldnt find that page.</h1>
        <p>You can return to the homepage by <Link to="/">clicking here</Link>.</p>
      </div>
    </Layout>
  )
}
